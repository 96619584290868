// extracted by mini-css-extract-plugin
export var ArtistDescription = "JoshSperling-module--ArtistDescription--rnCvc";
export var ArtistInfos = "JoshSperling-module--ArtistInfos--Ad7DQ";
export var ButtonWrapper = "JoshSperling-module--ButtonWrapper --LumQr";
export var CardWrapper = "JoshSperling-module--CardWrapper--M5Gx7";
export var CarrouselWrapper2 = "JoshSperling-module--CarrouselWrapper2--G-9WD";
export var Citations = "JoshSperling-module--Citations--5z4IS";
export var DescriptionWrapper = "JoshSperling-module--DescriptionWrapper--3EDhd";
export var ImageWrapper = "JoshSperling-module--ImageWrapper--Y98MT";
export var LinkWrapper = "JoshSperling-module--LinkWrapper--Nlw6h";
export var MobileProtrait = "JoshSperling-module--MobileProtrait--I7dDi";
export var More = "JoshSperling-module--More--6vI12";
export var MoreButton = "JoshSperling-module--MoreButton--yCAgv";
export var NameWrapper = "JoshSperling-module--NameWrapper--PBZp3";
export var PdpWrapper = "JoshSperling-module--PdpWrapper--ru6iM";
export var PhotosWrapper = "JoshSperling-module--PhotosWrapper--1QbSo";
export var ProfilWrapper = "JoshSperling-module--ProfilWrapper--hV80g";
export var TitleWrapper = "JoshSperling-module--TitleWrapper--8b4c1";
export var Wrapper = "JoshSperling-module--Wrapper--sHOhj";